export default {
    mutations: {
        storeUpdateName(state, object){
            Object.entries(object).forEach(([key, value]) => {
                state.name[key] = value
            });
        },
        storeUpdateTel(state, object){
            Object.entries(object).forEach(([key, value]) => {
                state.tel[key] = value
            });
        },
        storeUpdateMail(state, object){
            Object.entries(object).forEach(([key, value]) => {
                state.mail[key] = value
            });
        },
        storeUpdateDeparture(state, object){
            Object.entries(object).forEach(([key, value]) => {
                state.departure[key] = value
            });
        },
        storeUpdateDestination(state, object){
            Object.entries(object).forEach(([key, value]) => {
                state.destination[key] = value
            });
        },
        storeUpdateWeight(state, object){
            Object.entries(object).forEach(([key, value]) => {
                state.weight[key] = value
            });
        },
        storeUpdateVolume(state, object){
            Object.entries(object).forEach(([key, value]) => {
                state.volume[key] = value
            });
        },
        storeUpdatePlace(state, object){
            Object.entries(object).forEach(([key, value]) => {
                state.place[key] = value
            });
        },
    },
    state: {
        name: {
            type:'name',
            value: null,
            isRequire: true,
            note: null,
            noteEmpty() {
                return 'Введите имя'
            },
        },
        tel: {
            type:'tel',
            value: null,
            isRequire: true,
            note: null,
            hasValidation: true,
            noteEmpty() {
                return 'Введите телефон'
            },
            noteInvalid() {
                return 'Неверный формат телефона'
            },
            isValid() {
                return this.value.length === 18
            }
        },
        mail: {
            type:'mail',
            value: null,
            isRequire: true,
            hasValidation: true,
            note: null,
            noteEmpty() {
                return 'Введите почту'
            },
            noteInvalid() {
                return 'Неверный формат почты'
            },
            isValid() {
                const EMAIL_REGEXP =    /^(([^<>()[\]\\.,;:\s@"](\.[^<>()[\]\\.,;:\s@"])*)|.(".+")){2,}@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]{2,}\.)+[a-zA-Z]{2,}))$/
                return EMAIL_REGEXP.test(this.value);
            }
        },
        departure: {
            type:'departure',
            value: null,
            isRequire: false,
            hasValidation: false,
            note: null,
            noteEmpty() {
                return 'Введите город'
            }
        },
        destination: {
            type:'destination',
            value: null,
            isRequire: false,
            hasValidation: false,
            note: null,
            noteEmpty() {
                return 'Введите город'
            }
        },
        weight: {
            type:'weight',
            value: null,
            isRequire: false,
            hasValidation: false,
            note: null,
            noteEmpty() {
                return 'Введите вес'
            }
        },
        volume: {
            type:'volume',
            value: null,
            isRequire: false,
            hasValidation: false,
            note: null,
            noteEmpty() {
                return 'Введите объем'
            }
        },
        place: {
            type:'place',
            value: null,
            isRequire: false,
            hasValidation: false,
            note: null,
            noteEmpty() {
                return 'Введите количество мест'
            }
        },
    },
    getters: {
        storeGetName: state => key => {
            if(key){
                return state.name[key]
            } else {
                return state.name
            }
        },
        storeGetTel: state => key => {
            if(key){
                return state.tel[key]
            } else {
                return state.tel
            }
        },
        storeGetMail: state => key => {
            if(key){
                return state.mail[key]
            } else {
                return state.mail
            }
        },
        storeGetDeparture: state => key => {
            if(key){
                return state.departure[key]
            } else {
                return state.departure
            }
        },
        storeGetDestination: state => key => {
            if(key){
                return state.destination[key]
            } else {
                return state.destination
            }
        },
        storeGetWeight: state => key => {
            if(key){
                return state.weight[key]
            } else {
                return state.weight
            }
        },
        storeGetVolume: state => key => {
            if(key){
                return state.volume[key]
            } else {
                return state.volume
            }
        },
        storeGetPlace: state => key => {
            if(key){
                return state.place[key]
            } else {
                return state.place
            }
        },
    }
}