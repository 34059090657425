// Get запрос
export const fetchGet = async (url, obj, jwt) =>{
    let urlGet = new URL(url);

    Object.entries(obj).forEach(([key, value]) => {
        if(value != null){
            urlGet.searchParams.append(key, value)
        }
    });

    const request = {
        method: 'GET',
        headers: {
            "Authorization": 'Bearer ' + jwt,
            "Content-type": "application/json; charset=UTF-8",
            'accept': 'application/json'
        }
    };

    let status, result
    try {
        const response = await fetch(urlGet, request);
        status = response.status;
        result = await response.json()
    } catch (e) {
        status = 500
        result = null
    }

    return {status, result}
}

// Post запрос
export const fetchPost = async (url, obj, jwt) => {
    let urlPost = new URL(url);
    const request = {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
            "Authorization": 'Bearer ' + jwt,
            "Content-type": "application/json; charset=UTF-8",
            'accept': 'application/json',
        }
    };
    let status, result
    try {
        const response = await fetch(urlPost, request);
        status = response.status;
        result = await response.json()
    } catch (e) {
        status = 500
        result = null
    }
    return {status, result}
}

// Проверка запроса на успешность
export const isStatusSuccess = num => {
    return num >= 200 && num <= 300
}

// Преобразование невалидного пустого ответа к null
export const normalizeResult = result => {
    let res
    if(result === null || result === undefined){
        res = null
    } else if (result.length < 1){
        res = null
    } else if(Object.keys(result).length < 1){
        res = null
    } else {
        res = result
    }
    return res
}

export const normalizeResponse = response => {
    const status = isStatusSuccess(response.status) ? null : response.status
    const result = !status ? normalizeResult(response.result) : null
    return {status, result}
}

