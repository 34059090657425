<template>
  <form-element-container :has-background-grey="hasBackgroundGrey" :has-error="hasError">
    <template #before>
      <slot name="before"/>
    </template>
    <template #default>
      <FormElementInput
          :is-integer="isInteger"
          :is-float="isFloat"
          ref="input"
          :type="type"
          :label="label"
          :set-value="value"
          :placeholder="placeholder"
          :max-length="maxLength"
          @onInput="onInput"
      />
    </template>
    <template #reset>
      <IconBase v-if="value" @click="toResetInput" type="cross"/>
    </template>
    <template #after><slot name="after"/></template>
  </form-element-container>
</template>

<!-- Сюда нельзя caption потому что целефое действие в селекте может быть на весь компонент -->

<script>
import IconBase from "@/shared/ui/IconBase";
import FormElementInput from "@/shared/ui/FormElementInput";
import FormElementContainer from "@/shared/ui/FormElementContainer";

export default {
  name: "FormInput",
  components: {FormElementContainer, FormElementInput, IconBase},
  emits:['onInput'],
  props:{
    hasError:{
      type: Boolean,
      default(){
        return false
      }
    },
    hasBackgroundGrey:{
      type: Boolean,
      default(){
        return false
      }
    },
    label: {
      type: String,
      default() {
        return null
      }
    },
    placeholder: {
      type: String,
      default() {
        return null
      }
    },
    type: {
      type: String,
      default() {
        return 'text'
      }
    },
    maxLength: {
      type: [String, Number],
      default() {
        return 'auto'
      }
    },
    setValue: {
      type: [String, Number],
      require: false
    },
    isFloat:{
      type: Boolean,
      default(){
        return false
      }
    },
    isInteger:{
      type: Boolean,
      default(){
        return false
      }
    }
  },
  data(){
    return{
      value: this.setValue
    }
  },
  watch:{
    setValue(){
      this.value = this.setValue
      // console.log(this.value)
      this.$emit('onInput', this.value)
    }
  },
  methods:{
    onInput: function (value = null){
      // if(this.isFloat){
      //   const float = value.replace(/[^0-9,.]+/gi, '')
      //   this.value = float.replace(',', '.')
      //   console.log(this.value)
      // } else {
        this.value = value
      // }
      this.$emit('onInput', value)
    },
    toResetInput: function (){
      this.$emit('onInput', null)
      this.$refs.input.toResetInput()
      // this.$refs.input.toFocusInputField()
    }
  },

}
</script>

<style scoped>

</style>