<template>
  <label :class="['form-element__label', {'form-element__label--active' : isActive}]"
  >{{ text }}</label>
</template>

<script>
export default {
  name: "FormElementLabel",
  props:{
    isActive:{
      type:Boolean,
      default(){
        return false
      }
    },
    text:{
      type: String,
      require: false
    }
  }
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
@import "@/shared/styl/mixins.styl"
.form-element
  &__label
    fontDefault()
    color #C2C2C2
    position absolute
    left 0
    top 50%
    transform translateY(-50%)
    transition 100ms
    pointer-events none
    display inline
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
    max-width 100%
    &--active
      font-size 12px
      top 3px
      left -1px
      transform translateY(0)
</style>