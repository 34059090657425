<template>
  <div class="to-order" data-id="orderCreate" @click.capture="onClick($el)">
    <slot/>
  </div>
</template>

<script>

import {scrollToId} from "@/shared/methods/scroll";

export default {
  name: "ToOrder",
  methods:{
    onClick: function (el){
      console.log(el)
      scrollToId(el)
      this.$emit('clicked')
    }
  },
}
</script>

<style lang="stylus">
.to-order
  cursor pointer
</style>