<template>
  <div class="form-caption"><slot/></div>
</template>

<script>
export default {
  name: "FormCaption"
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl";
@import "@/shared/styl/mixins.styl";
.form-caption
  fontCaption()
  color $grey
</style>