<template>
  <div class="accordion-base">
    <div class="accordion-base__headline" @click="isActive = !isActive">
      <slot name="action"></slot>
    </div>
    <transition name="fade">
      <div :class="['accordion-base__body', {'accordion-base__body--active': isActive}]">
        <slot name="area"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "AccordionBase",
  props:{
    setIsActive: {
      type: Boolean,
      default(){
        return false
      }
    },
    id: {
      type: [String, Number]
    },

  },
  emits:['changeStatus'],
  data(){
    return{
      isActive: this.setIsActive
    }
  },
  watch:{
    isActive(){
      this.$emit('changeStatus', this.id, this.isActive)
    }
  }
}
</script>

<style lang="stylus">
.accordion-base
  &__headline
    fontH4Bold()
    color inherit
    display flex
    justify-content flex-start
    align-items center
    cursor pointer
  &__body
    transition 300ms
    opacity 0
    visibility hidden
    max-height 0
    &--active
      top 0
      opacity 1
      visibility visible
      max-height none
</style>