<template>
  <IconBase
      type="loader"
      class="icon-base--loader"
  />
</template>

<script>

import IconBase from "@/shared/ui/IconBase";
export default {
  name: "IconLoader",
  components: {IconBase}
}
</script>

<style lang="stylus">
.icon-base--loader
  position relative
  top -4px
  display inline-block
  width 30px
  height 30px
  -webkit-animation rotate-right 1s linear infinite both
  animation rotate-right 1s linear infinite both

@keyframes rotate-right
  0%
    transform rotate(0deg)
  100%
    transform rotate(360deg)
</style>