<template>
  <modal-base ref="modalMore">
    <template #action>
      <link-base>+ Добавить информацию о грузе</link-base>
    </template>
    <template #area>
      <h3 class="modal__title">Расчет стоимости перевозки</h3>
      <order-create-form @isSuccess="onSuccessOrderCreate">
        <template #callback>
          <FormCallback ref="formCallback"/>
        </template>
        <template #cargo>
          <FormCargo ref="formCargo"/>
        </template>
      </order-create-form>
    </template>
  </modal-base>
  <OrderCreateSuccess v-if="isSuccessOrderCreate" @isSuccess="onSuccessOrderCreate"/>
</template>

<script>
import OrderCreateForm from "@/features/order-create/OrderCreateForm";
import FormCallback from "@/features/order-create/FormCallback";
import FormCargo from "@/features/order-create/FormCargo";
import ModalBase from "@/shared/ui/ModalBase";
import LinkBase from "@/shared/ui/LinkBase";
import OrderCreateSuccess from "@/features/order-create/OrderCreateSuccess";
export default {
  name: "FormButtonMore",
  components: {OrderCreateSuccess, LinkBase, ModalBase, FormCargo, FormCallback, OrderCreateForm},
  data(){
    return{
      isSuccessOrderCreate: false
    }
  },
  methods:{
    onSuccessOrderCreate: function (boolean){
      this.isSuccessOrderCreate = boolean
      if(boolean){
        this.$refs.formCallback.toReset()
        this.$refs.formCargo.toReset()
        this.$refs.modalMore.toHide()
      }
    },
  }
}
</script>

<style lang="stylus">
.modal__title
  font-size 16px
  font-weight 600
  //margin-bottom 20px
  text-align center
</style>