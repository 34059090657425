<template>
  <button-base
      :is-loading="isLoading"
      :is-disabled="isDisabled"
      :class="['button-icon', {'button-icon--small': isSmall}]"
  >
    <IconBase :type="type"/>
  </button-base>
</template>

<script>
import IconBase from "@/shared/ui/IconBase";
import ButtonBase from "@/shared/ui/ButtonBase";
export default {
  name: "ButtonIconBase",
  components: {ButtonBase, IconBase, },
  props:{
    type:{
      type: String
    },
    isLoading:{
      type:Boolean,
      default(){
        return false
      }
    },
    isDisabled:{
      type:Boolean,
      default(){
        return false
      }
    },
    isSmall:{
      type:Boolean,
      default(){
        return false
      }
    }
  }
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
.button-icon
  width 50px
  height 50px
  background inherit
  color inherit
  display flex
  justify-content center
  align-items center
  cursor pointer
  border-radius 50%
  &--small
    width 30px
    height 30px
</style>