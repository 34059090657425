import {createStore} from "vuex"
import storeWindow from "@/shared/store/storeWindow"
import storeOrder from "@/shared/store/storeOrder"
import storeContent from "@/shared/store/storeContent"
export const store = createStore({
    modules:{
        storeWindow,
        storeOrder,
        storeContent
    }
})