<template>
  <div class="form-element__field">
    <input
        ref="input"
        :type="type"
        :maxlength="maxLength"
        :class="['form-element__input', {
                'form-element__input--padding-top' : label,
                'form-element__input--single' : hasSingleSymbol
              }]"
        :placeholder="!label ? placeholder : ''"
        v-model.trim="value"
        @input="onInput()"

    >
    <FormElementLabel
        v-if="label"
        :is-active="!!value"
        :text="label"
        class="form-element__field__label"
    />
  </div>

</template>

<script>
import FormElementLabel from "@/shared/ui/FormElementLabel";

export default {
  name: "FormElementInput",
  components: {FormElementLabel},
  emits:['onInput'],
  props:{
    label: {
      type: String,
      require: false
    },
    placeholder: {
      type: String,
      default() {
        return null
      }
    },
    type: {
      type: String,
      default() {
        return 'text'
      }
    },
    maxLength: {
      type: [String, Number],
      default() {
        return 'auto'
      }
    },
    setValue: {
      type: [String, Number],
      require: false
    },
    isFloat:{
      type: Boolean,
      default(){
        return false
      }
    },
    isInteger:{
      type: Boolean,
      default(){
        return false
      }
    }
  },
  data(){
    return{
      value: this.setValue,
    }
  },
  computed: {
    hasSingleSymbol() {
      return this.maxLength === 1
    },
  },
  watch:{
    setValue(){
      this.value = this.setValue
    }
  },
  methods: {
    onInput: function () {
      if(this.isFloat && this.value){
        const float = this.value.replace(/[^0-9,.]+/gi, '')
        const replaceSymbol = float.replace(',', '.')
        this.value = replaceSymbol.replace(/[.]+/gi, '.')
      }
      if(this.isInteger && this.value){
        this.value = this.value.replace(/[^0-9]+/gi, '')
      }
      this.$emit('onInput', this.value)
    },

    toFocusInputField: function () {
      setTimeout(() => {
        this.$refs.input.focus()
      }, 10);
    },

    toResetInput: function () {
      this.value = null
      this.onInput()
    }
  }
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
@import "@/shared/styl/mixins.styl"
.form-element
  &__field
    position relative
    height 100%
    margin-left 10px
    ^[0]__input:focus + &__label
      font-size 12px
      top 3px
      left -1px
      transform translateY(0)
  &__input
    resetStyleInput()
    fontDefault()
    color $blackSecondary
    &::placeholder
      fontDefault()
      color $grey
    &:-webkit-autofill
      backgroundAutofillInput($white)
    &--single
      fontH3()
      text-align center
    &--padding-top
      padding-top 16px
    &--grey &:-webkit-autofill
      backgroundAutofillInput($greyLight)

  //&__label
  //  color $grey
  //  position absolute
  //  left 0
  //  top 50%
  //  transform translateY(-50%)
  //  transition 100ms
  //  pointer-events none
  //  &--active,
  //  ^[0]__input:focus + &
  //    font-size 12px
  //    top 3px
  //    left -1px
  //    transform translateY(0)
</style>