<template>
  <modal-base ref="modalSuccess" class="modalSuccess">
    <template #area>
      <div class="order-create__success">
        <div class="order-create__success__title">Заявка успешно отправлена</div>
        <div class="order-create__success__description">В ближайшее время с вами свяжуться наши менеджеры</div>
        <button-text-base class="order-create__success__button" @click="onClickSuccessButton">Ок</button-text-base>
      </div>
    </template>
  </modal-base>
</template>

<script>
import ModalBase from "@/shared/ui/ModalBase";
import ButtonTextBase from "@/shared/ui/ButtonTextBase";
export default {
  name: "OrderCreateSuccess",
  components: {ButtonTextBase, ModalBase},
  emits:['isSuccess'],
  mounted() {
    setTimeout(() =>{
      this.$refs.modalSuccess.toShow()
    }, 1)
  },
  methods:{
    onClickSuccessButton: function (){
      this.$refs.modalSuccess.toHide()
      this.$emit('isSuccess', false)
    }
  }
}
</script>

<style lang="stylus">
.order-create__success
  text-align center
  &__title
    font-weight 600
    font-size 20px
    margin-bottom 10px
    color $green
    @media screen and (min-width 768px)
      font-size 24px
  &__description
    color #57585A
  &__button
    margin-top 30px
</style>