<template>
  <div class="form-element__field">
    <input
        ref="input"
        type="tel"
        :maxlength="maxlength"
        :class="['form-element__input', {
                'form-element__input--padding-top' : label,
                'form-element__input--single' : hasSingleSymbol
              }]"
        :placeholder="!label ? placeholder : ''"
        v-model.trim="value"
        @input="onInput($event)"
        @paste="onPaste($event)"
        @keydown.delete="onDelete($event)"

    >
    <FormElementLabel
        v-if="label"
        :is-active="!!value"
        :text="label"
        class="form-element__field__label"
    />
  </div>

</template>

<script>
import FormElementLabel from "@/shared/ui/FormElementLabel";
export default {
  name: "FormElementInputTel",
  components: {FormElementLabel},
  emits:['onInput', 'onPaste'],
  props:{
    label: {
      type: String,
      require: false
    },
    placeholder: {
      type: String,
      default() {
        return null
      }
    },
    maxLength: {
      type: [String, Number],
      default() {
        return 'auto'
      }
    },
    setValue: {
      type: String,
      require: false
    },
    isRu: {
      type: Boolean,
      default(){
        return true
      }
    },
  },
  data(){
    return{
      value: this.setValue,
      maxlength: 18
    }
  },
  computed: {
    hasSingleSymbol() {
      return this.maxLength === 1
    },
  },
  watch:{
    isRu(){
      this.value = null
    },
    setValue(){
      this.value = this.setValue
    }
  },
  methods: {

    onDelete: function (){
      if(this.value){
        if(this.onlyNumbers(this.value).length === 1){
          this.value = null
        }
      }
    },

    onPaste: function (event){
      if(this.isRu){
        let clipboardData = event.clipboardData || window.clipboardData;
        const pasted = clipboardData.getData('Text');
        if(pasted){
          const numbers = this.onlyNumbers(pasted)
          const replaced = this.replaceFirstNumberRu(numbers)
          this.value = this.addMaskRu(replaced)
          this.$emit('onPaste', this.value)
        }
      }
    },

    onlyNumbers: function(value){
      if(value.length === 1 && value === '+'){
        return value
      } else {
        return value.replace(/\D/g, '')
      }
    },

    addMaskRu: function (numbers){
      let telFormatted = ''
      if(this.isRu){
        telFormatted = '+7'
        // российский номер
        if(numbers.length > 1){
          telFormatted += ' (' + numbers.substring(1,4)
        }
        if(numbers.length >= 5){
          telFormatted += ') ' + numbers.substring(4,7)
        }
        if(numbers.length >= 8){
          telFormatted += ' ' + numbers.substring(7,9)
        }
        if(numbers.length >= 10){
          telFormatted += ' ' + numbers.substring(9,11)
        }
      } else {
        // НЕроссийский номер
        telFormatted = '+'
        telFormatted = telFormatted += numbers.substring(0, 16) // максимальное кол-во символов
      }

      return telFormatted
    },

    replaceFirstNumberRu: function (numbers){
      const number = numbers[0]
      if(number === '8' || number === '7' || number === '+') {
        return '7' + numbers.substring(1, numbers.length)
      } else if(number === '9'){
        return '7' + numbers
      }
      else {
        return this.onlyNumbers(numbers)
      }
    },

    replaceFirstNumberOther: function (numbers){
      const number = numbers[0]
      if(number === '+') {
        return ' ' + numbers.substring(1, numbers.length)
      } else {
        return numbers
      }
    },

    onInput: function (event) {
      const value = this.value
      if(value){
        let notReplaced = this.onlyNumbers(value)
        let numbers = this.isRu ? this.replaceFirstNumberRu(notReplaced) : this.replaceFirstNumberOther(notReplaced)
        if(numbers){
          const element = event.target
          const cursorPos = element.selectionStart

          // Редактирование изменения
          if(value.length !== cursorPos){
            if(!event.data){
              this.value = this.addMaskRu(numbers)
              window.setTimeout(function() {
                element.setSelectionRange(cursorPos, cursorPos);
              }, 0)
            }

            if(event.data && /\D/g.test(event.data)){
              // если написали новую цифру
              window.setTimeout(function() {
                element.setSelectionRange(cursorPos, cursorPos);
              }, 0)
              this.value = numbers
            }
            return;
          }
          this.value = this.addMaskRu(numbers)
        } else {
          this.value = null
        }
      } else {
        this.value = null
      }

      this.$emit('onInput', this.value)
    },

    toFocusInputField: function () {
      setTimeout(() => {
        this.$refs.input.focus()
      }, 10);
    },

    toResetInput: function () {
      this.value = null
      this.onInput()
    }
  }
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
@import "@/shared/styl/mixins.styl"
.form-element
  &__field
    position relative
    height 100%
    margin-left 10px
    ^[0]__input:focus + &__label
      font-size 12px
      top 3px
      left -1px
      transform translateY(0)
  &__input
    resetStyleInput()
    fontDefault()
    color $blackSecondary
    &::placeholder
      fontDefault()
      color $grey
    &:-webkit-autofill
      backgroundAutofillInput($white)
    &--single
      fontH3()
      text-align center
    &--padding-top
      padding-top 16px
    &--grey &:-webkit-autofill
      backgroundAutofillInput($greyLight)

  //&__label
  //  color $grey
  //  position absolute
  //  left 0
  //  top 50%
  //  transform translateY(-50%)
  //  transition 100ms
  //  pointer-events none
  //  &--active,
  //  ^[0]__input:focus + &
  //    font-size 12px
  //    top 3px
  //    left -1px
  //    transform translateY(0)
</style>