export default {
    mutations: {
        updateWindowSize(state, obj){
            state.windowSize = obj
        }
    },
    state: {
        windowSize: null
    },
    getters: {
        getWindowSize(state){
            return state.windowSize
        }
    }
}