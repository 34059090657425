export default {
    mutations: {

    },
    state: {
        policy: {
            date: '',
            content: `
<h1>Политика общества с ограниченной ответственностью "ТИЗА Логистик " в отношении обработки персональных данных</h1>
<h2>Общие положения</h2>
<ol>
    <li>1.1 Настоящая Политика в отношении обработки персональных данных (далее – «Политика») подготовлена в соответствии с п. 2 ч. 1 ст. 18.1 Федерального закона Российской Федерации «О персональных данных» №152-ФЗ от 27 июля 2006 года (далее – «Закон») и определяет позицию ООО " ТИЗА Логистик" (далее – ООО «ТИЗА») в области обработки и защиты персональных данных (далее – персональные данные), в отношении соблюдения прав и свобод, описывает особенности сбора, хранения, использования и передачи персональных данных, реализуемые требования к их защите, а также информацию о правах лиц, к которым относятся соответствующие персональные данные.</li>
    <li>1.2 Под персональными данными понимается любая информация, относящаяся прямо или косвенно определенному или определяемому физическому лицу (гражданину)</li>
    <li>1.3 Под обработкой персональных данных понимается любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации и/или без использования таких средств. К таким действиям (операциям) относятся в частности: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ), сопоставление, обезличивание, блокирование, удаление, уничтожение персональных данных</li>
    <li>1.4 Под безопасностью персональных данных понимается защищенность персональных данных от неправомерного и/или несанкционированного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных</li>
    <li>1.5 Политика распространяется на все действия, связанные с обработкой персональных данных на сайте https://tisalog.ru/.</li>
    <li>1.6 Использование Сайта Пользователем также означает его безоговорочное согласие с правилами Политики Конфиденциальности и указанными в ней условиями обработки персональных данных. Если Пользователь не согласен с условиями настоящей Политики, Пользователю следует воздержаться от использования Сайта.</li>
    <li>1.7 Настоящая Политика распространяется на персональные данные, полученные как до, так и после ввода в действие настоящей Политики.</li>
</ol>
<h2>2. Правовые основания и цели обработки Персональных данных ООО «ТИЗА».</h2>
<ol>
    <li>2.1 ООО «ТИЗА» осуществляет обработку и обеспечивает безопасность персональных данных для осуществления возложенных на ООО «ТИЗА» законодательством России функций, полномочий и обязанностей в том числе, но не ограничиваясь, в соответствии с Конституцией Российской Федерации, федеральными законами, в частности Федеральным законом №152-ФЗ от 27 июля 2006 года "О персональных данных", подзаконных актов, других определяющих случаи и особенности обработки указанных персональных данных федеральных законов Российской Федерации, руководящих и методических документов ФСТЭК России и ФСБ России, а также Гражданским кодексом Российской Федерации, Налоговым кодексом Российской Федерации, Законом РФ №2300-1 от 7 февраля 1992 г. "О защите прав потребителей", Федеральным законом № 402-ФЗ от 6 декабря 2011 г. "О бухгалтерском учете", а также операторами персональных данных, уставом и локальными актами ООО «ТИЗА»</li>
    <li>2.2 ООО «ТИЗА» является оператором персональных данных в соответствии с Законом</li>
    <li>2.3 Обработка персональных данных может осуществляться ООО «ТИЗА» в следующих случаях и на следующих правовых основаниях
        <ul>
            <li>• обработка персональных данных осуществляется только с согласия субъекта персональных данных, выраженного в любой форме, позволяющей подтвердить факт получения согласия, в том числе способами, указанными в п. 1.6. настоящей Политики;</li>
            <li>• для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления и выполнения возложенных законодательством Российской Федерации на оператора персональных данных функций, полномочий и обязанностей;</li>
            <li>• для исполнения договора на оказание логистических услуг между ООО «ТИЗА» и пользователем Сайта, который является физическим лицо, обладающим необходимым объемом дееспособности и имеющим намерение оформить или оформляющим Заказ на услуги через Сайт;</li>
            <li>• для осуществления прав и законных интересов оператора или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных;</li>
            <li>• в статистических или иных маркетинговых и исследовательских целях при условии обязательного обезличивания персональных данных;</li>
            <li>• в иных целях, предусмотренных настоящей Политикой.</li>
        </ul>
    </li>
    <li>2.4. Субъектами персональных данных, обрабатываемых посредством Сайта, являются, в том числе пользователи Сайта, грузоотправители/ грузополучатели; любые иные физические лица, обращающиеся в ООО «ТИЗА» с заявлениями /обращениями</li>
    <li>2.5 ООО «ТИЗА» осуществляет обработку персональных данных клиентов для:</li>
    <li>2.5.1. заключения и исполнения договоров, одной из сторон которых является Пользователь, в соответствии с Условиями заказа и доставки, в том числе договора на оказание логистических услуг, в т. ч. заключенного дистанционным способом на Сайте</li>
    <li>2.5.2. предоставления информации об услугах, Акциях и специальных предложениях</li>
    <li>2.5.3 информирования о статусе Заявки</li>
    <li>2.5.4. содействия в урегулировании претензий</li>
    <li>2.5.5. предоставление возможности оставлять отзывы об оказанных услугах</li>
    <li>2.5.6. анализа качества предоставляемого ООО «ТИЗА» сервиса и улучшению качества обслуживания клиентов ООО «ТИЗА»</li>
    <li>2.5.7. идентификации Пользователей и Клиентов в Ак</li>
</ol>

<h2>3. Категории персональных данных, обрабатываемых ООО «ТИЗА»</h2>
<ol>
    <li>
    <p>3.1 ООО «ТИЗА» обрабатывает следующие категории персональных данных Клиентов</p>
    - сведения, полученные при регистрации, авторизации и оформлении заказа (фамилию, имя, отчество, номер телефона, адрес электронной почты, адрес доставки, cookie);<br>
    - сведения, полученные при взаимодействии с Клиентами <br>
    - сведения, полученные в связи с размещением или исполнением Заказов Клиента<br>
    (сведения о грузе, способе и состоянии оплаты оказанных услуг, данные грузополучателя/грузоотправителя/ плательщика) <br>
    - сведения о претензиях Клиента (переданных с помощью Сайта или иным образом);<br>
    - размещенные Клиентом отзывы об услугах;<br>
    - информацию о геолокации.<br>
    </li>
    <li>3.2 ООО «ТИЗА» обрабатывает следующие категории персональных данных субъектов персональных данных, обращающихся в ООО «ТИЗА» с заявлениями о предполагаемом нарушении их прав: фамилия, имя, отчество, паспортные данные (данные иного документа, удостоверяющего личность), контактная информация (номера телефона и (или) факса, адрес электронной почты) правообладателя или иного лица, чье право предположительно было нарушено, и (или) заявителя, если он действует в качестве уполномоченного представителя правообладателя или иного лица, чье право предположительно было нарушено, информацию о полученных заявлениях, ходе и результате их рассмотрения.</li>
    <li>3.3 Персональные данные, указанные в пунктах настоящего Раздела выше, могут быть получены ООО «ТИЗА» в ходе предоставления Клиентом персональных данных путем заполнения соответствующих форм на Сайте, посредством направления корреспонденции или электронных писем на адреса электронной почты ООО «ТИЗА».</li>
</ol>
<h2>4. Принципы и условия обработки Персональных Данных</h2>
<ol>
    <li>4.1 При обработке персональных данных ООО «ТИЗА» придерживается следующих принципов
        <ul>
            <li>· обработка персональных данных осуществляется на законной основе;</li>
            <li>· персональные данные не раскрываются третьим лицам и не распространяются без согласия субъекта персональных данных, за исключением предусмотренных законодательством России случаев;</li>
            <li>· определение конкретных законных целей до начала обработки (в т. ч. сбора) персональных данных;</li>
            <li>· объединение баз данных, в том числе, баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой, не допускается;</li>
            <li>· обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей;</li>
            <li>· обрабатываемые персональные данные подлежат уничтожению или обезличиванию при отзыве согласия на их обработку субъектом персональных данных, по достижению целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом;</li>
            <li>· содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Обрабатываемые персональные данные не являются избыточными по отношению к заявленным целям обработки;</li>
            <li>· при обработке персональных данных обеспечивается точность персональных данных и их достаточность, в случаях необходимости и актуальность персональных данных по отношению к заявленным целям их обработки;</li>
            <li>· хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем, по которому является субъект персональных данных.</li>
        </ul>
    </li>
    <li>4.2 ООО «ТИЗА» не осуществляет обработку персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных, философских и иных убеждений, состояния здоровья, личной жизни, членства в общественных объединениях, в том числе в профессиональных союзах, биометрических данных. ООО «ТИЗА» не осуществляет голосовой ввод и верификацию персональных данных с использованием незащищенных каналов передачи данных. Передача данных субъектами персональных данных работникам носит добровольный характер и подразумевает, что полученные данные будут использованы в соответствии с настоящей Политикой</li>
    <li>4.3 ООО «ТИЗА» обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных с использованием баз данных, находящихся на территории России, и не осуществляет трансграничную передачу персональных данных</li>
    <li>4.4 ООО «ТИЗА» вправе поручить обработку персональных данных посредством совершения действий, указанных в п. 1.3. настоящей Политики третьим лицам, в том числе третьим лицам, перечисленным в п. 4.5. настоящей Политики, с согласия субъекта персональных данных, выраженного путем предоставления согласия, на основании заключаемого с такими третьими лицами договора, в следующих случаях
        <ul>
            <li>· для обработки обращений Клиентов, третьих лиц посредством голосовых и неголосовых каналов связи, включая электронную почту, онлайн-чат, приложения для мобильных устройств и (или) социальные сети;</li>
            <li>· в целях доставки груза Клиенту (грузополучателю);</li>
            <li>· в целях анализа качества предоставляемого ООО «ТИЗА» сервиса и улучшению качества обслуживания клиентов ООО «ТИЗА»;</li>
            <li>· в целях подготовки персональных предложений и условий обслуживания;</li>
            <li>· в целях персонализации рекламы;</li>
            <li>· в целях отправки рекламных и информационных сообщений;</li>
            <li>· в целях проведения статистических или иных маркетинговых исследований.</li>
        </ul>
    </li>
    <li>4.5. ООО «ТИЗА» вправе посредством сети «Интернет» с соблюдением положений действующего законодательства РФ передавать персональные данные Клиентов (Ф.И.О.; дата рождения, e-mail; номер мобильного телефона; информация о действиях Клиента, совершенных в рамках использования Сайта) третьим лицам, с целью предоставления такими третьими лицами (самостоятельно или совместно) Клиентам рекламных и информационных материалов по продуктам таких третьих лиц, с целью подготовки персональных предложений и формирования индивидуальных условий обслуживания Клиентов, а также с целью проведения маркетинговых исследований и рассылок</li>
    <li>4.6 ООО «ТИЗА» вправе передавать персональные данные третьим лицам с согласия субъекта персональных данных в следующих случаях</li>
    <li>4.6.1 контрагентам для целей исполнения Заказа Клиента,</li>
    <li>4.6.2 в целях возможности защиты прав и законных интересов ООО «ТИЗА» или третьих лиц в случаях, когда субъект персональных данных нарушает какие-либо условия пользовательских документов Сайта и (или) настоящей Политикой</li>
    <li>4.6.3 по вступившему в силу судебному решению или в случаях, установленных действующим законодательством</li>
    <li>4.7 В целях улучшения качества обслуживания Клиентов, статистических или иных маркетинговых и исследовательских целях, ООО «ТИЗА» развивает, совершенствует, оптимизирует и внедряет новый функционал Сайта. Для обеспечения указанного, Клиент соглашается и поручает ООО «ТИЗА» осуществлять с соблюдением применимого законодательства обработку (включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), сопоставление, извлечение, использование, обезличивание, блокирование, удаление и уничтожение) учетных и любых иных персональных данных Клиента, в том числе имеющихся в распоряжении партнера, действующего по поручению ООО «ТИЗА», передачу имеющихся в распоряжении ООО «ТИЗА» учетных и любых иных персональных данных Клиента партнерам во исполнение такого поручения на обработку и получение от партнеров по итогам обработки аналитической информации, включая результатов автоматизированной обработки таких данных, в том числе в виде целочисленных и/или текстовых значений и идентификаторов</li>
    <li>4.8 ООО «ТИЗА» вправе передавать персональные данные третьим лицам с согласия субъекта персональных данных, а также в случаях, когда возможность передачи персональных данных третьим лицам прямо предусмотрена законодательством России не требуют согласия субъекта персональных данных</li>
    <li>4.9 В целях исполнения требований действующего законодательства России и своих договорных обязательств обработка персональных данных ООО «ТИЗА» осуществляется как с использованием, так и без использования средств автоматизации. Совокупность операций обработки включает сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных</li>
    <li>4.10 ООО «ТИЗА» не осуществляет принятия решений, порождающих юридические последствия в отношении субъекта персональных данных или иным образом затрагивающих его права и законные интересы, на основании исключительно автоматизированной обработки персональных данных</li>
    <li>4.11 ООО «ТИЗА» не проверяет и, как правило, не имеет возможности проверить актуальность и достоверность предоставляемой субъектами персональных данных сведений, полученных посредством Сайта. ООО «ТИЗА» исходит из того, что субъекты персональных данных, действуя разумно и добросовестно, предоставляют достоверные и достаточные персональные данные и поддерживают их в актуальном состоянии. </li>
</ol>
<h2>5. Права субъектов персональных данных</h2>
<ol>
    <li>5.1 Субъект персональных данных, персональные данные которого обрабатываются ООО «ТИЗА», имеет право
        <ul>
        <li>• в любой момент изменить (обновить, дополнить) предоставленные им персональные данные, зайдя в Личный кабинет в случаях, когда это позволяет сделать функционал Сайта;</li>
        <li>• удалить предоставленные им персональные данные путем направления ООО «ТИЗА» письменного уведомления с указанием персональных данных, которые должны быть удалены;</li>
        <li>• получать от ООО «ТИЗА»:</li>
        <li>· подтверждение факта обработки персональных данных и сведения о наличии персональных данных, относящихся к соответствующему субъекту персональных данных;</li>
        <li>· сведения о правовых основаниях и целях обработки персональных данных;</li>
        <li>· сведения о применяемых ООО «ТИЗА» способах обработки персональных данных;</li>
        <li>· сведения о наименовании и местонахождении ООО «ТИЗА»;</li>
        <li>· сведения о лицах (за исключением работников ООО «ТИЗА»), которые имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании договора с ООО «ТИЗА» или на основании федерального закона;</li>
        <li>· обрабатываемые персональные данные, относящиеся к субъекту персональных данных, и информацию об источнике их получения, если иной порядок предоставления таких персональных данных не предусмотрен законодательством;</li>
        <li>· сведения о сроках обработки персональных данных, в том числе о сроках их хранения;</li>
        <li>· сведения о порядке осуществления субъектом персональных данных прав, предусмотренных законодательством о персональных данных;</li>
        <li>· наименование и адрес лиц, осуществляющих обработку персональных данных по поручению ООО «ТИЗА»;</li>
        <li>· иные сведения, предусмотренные Законом;</li>
        <li>• требовать от ООО «ТИЗА» уточнения своих персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки; </li>
        <li>• отозвать свое согласие на обработку персональных данных в любой момент, направив электронное сообщение с электронной подписью по адресам электронной почты: sale@tisalog.ru либо направив письменное уведомление по адресу ООО «ТИЗА». </li>
        После получения такого сообщения обработка персональных данных субъекта персональных данных будет прекращена, а его персональные данные будут удалены, за исключением случаев, когда обработка может быть продолжена в соответствии с законодательством Российской Федерации. При этом направление такого сообщения об отзыве согласия Пользователем также должно считаться уведомлением об одностороннем отказе от исполнения соответствующего Заказа и влечет невозможность его исполнения;<br>
        <li>• требовать устранения неправомерных действий ООО «ТИЗА» в отношении его персональных данных;</li>
        <li>• принимать предусмотренные законом меры по защите своих прав и законных интересов, в том числе на возмещения убытков и (или) компенсацию морального вреда в судебном порядке.</li>
        </ul>
    </li>
    <li>5.2 Права, предусмотренные подпунктами настоящего Раздела выше, могут быть ограничены в соответствии с требованиями законодательства России и (или) в случаях, когда ООО «ТИЗА» осуществляет обработку персональных данных на иных правовых основаниях, нежели согласие субъекта персональных данных.</li>
</ol>

<h2>6. Обязанности ООО «ТИЗА»</h2>
<p>В соответствии с требованиями Закона ООО «ТИЗА» обязан:</p>
<p>1) предоставлять субъекту персональных данных по его запросу информацию, касающуюся обработки его персональных данных, либо на законных основаниях предоставить отказ в течение тридцати дней с даты получения запроса субъекта персональных данных или его представителя;</p>
<p>2) по требованию субъекта персональных данных уточнять, блокировать или удалять обрабатываемые персональные данные, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки в срок, не превышающий семи рабочих дней со дня предоставления субъектом персональных данных или его представителем сведений, подтверждающих эти факты;</p>
<p>3) вести журнал учета обращений субъектов персональных данных, в котором должны фиксироваться запросы субъектов персональных данных на получение персональных данных, а также факты предоставления персональных данных по этим запросам;</p>
<p>4) уведомлять субъекта персональных данных об обработке персональных данных в том случае, если персональные данные были получены не от субъекта персональных данных.</p> 
<p>Исключение составляют следующие случаи:</p>
<p>- субъект персональных данных уведомлен об осуществлении обработки ООО «ТИЗА» его персональных данных;</p>
<p>- персональные данные получены ООО «ТИЗА» в связи с исполнением договора, стороной которого либо выгодоприобретателем или поручителем, по которому является субъект персональных данных или на основании требования действующего законодательства;</p>
<p>- персональные данных сделаны общедоступными субъектом персональных данных или получены из общедоступного источника;</p>
<p>- ООО «ТИЗА» осуществляет обработку обезличенных персональных данных для статистических или иных исследовательских целей, если при этом не нарушаются права и законные интересы субъекта персональных данных;</p>
<p>- предоставление субъекту персональных данных сведений, содержащихся в уведомлении об обработке персональных данных, нарушает права и законные интересы третьих лиц;</p>
<p>5) в случае достижения цели обработки персональных данных незамедлительно прекратить обработку персональных данных и уничтожить соответствующие персональные данные в срок, не превышающий тридцати дней с даты достижения цели обработки персональных данных, если иное не предусмотрено договором, стороной которого,</p>
<p>выгодоприобретателем или поручителем по которому является субъект персональных данных, иным соглашением между ООО «ТИЗА» и субъектом персональных данных либо если ООО «ТИЗА» не вправе осуществлять обработку персональных данных без согласия субъекта персональных данных на основаниях, предусмотренных Законом или другими федеральными законами;</p>
<p>6) в случае отзыва субъектом персональных данных согласия на обработку своих персональных данных прекратить обработку персональных данных и уничтожить персональных данных в срок, не превышающий тридцати дней с даты поступления указанного отзыва, если иное не предусмотрено соглашением между ООО «ТИЗА» и субъектом персональных данных. Об уничтожении персональных данных ООО «ТИЗА» обязана уведомить субъекта персональных данных;</p>
<p>7) в случае поступления требования субъекта персональных данных о прекращении обработки персональных данных, полученных в целях продвижения услуг на рынке, немедленно прекратить обработку персональных данных;</p>
<p>8) при сборе персональных данных, в том числе посредством сети "Интернет", ООО «ТИЗА» обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации.<</p>

<h2>7. Защита персональных данных</h2>
<ol>
    <li>7.1 ООО «ТИЗА» при обработке персональных данных принимает необходимые правовые, организационные и технические меры для защиты персональных данных от неправомерного и (или) несанкционированного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных.</li>
    <li>7.2 К таким мерам, в частности, относятся:
        <ul>
            <li>• назначение лица, ответственного за организацию обработки персональных данных, и лица, ответственного за обеспечение безопасности данных;</li>
            <li>• разработка и утверждение локальных актов по вопросам обработки и защиты персональных данных;</li>
            <li>• применение правовых, организационных и технических мер по обеспечению безопасности персональных данных.</li>
            <li>• контроль за принимаемыми мерами по обеспечению безопасности персональных данных и уровнем защищенности информационных систем персональных данных;</li>
            <li>• оценка вреда, который может быть причинен субъектам персональных данных в случае нарушения требований законодательства о персональных данных, соотношение указанного вреда и принимаемых ООО «ТИЗА» мер, направленных на обеспечение выполнения обязанностей, предусмотренных законодательством о персональных данных;</li>
            <li>• соблюдение условий, исключающих несанкционированный доступ к материальным носителям персональных данных и обеспечивающих сохранность персональных данных;</li>
            <li>• ознакомление работников ООО «ТИЗА», непосредственно осуществляющих обработку персональных данных, с положениями законодательства России о персональных данных, в том числе с требованиями к защите персональных данных, локальными актами по вопросам обработки и защиты персональных данных, и обучение работников ООО «ТИЗА».</li>
        </ul>
    </li>
</ol>
<h2>8. Сроки обработки (хранения) Персональных Данных</h2>
<ol>
    <li>8.1 Сроки обработки (хранения) персональных данных определяются исходя из целей обработки персональных данных, в соответствии со сроком действия договоров с субъектами персональных данных, требованиями действующего законодательства:
        <ul>
            <li>- персональные данные зарегистрированных Пользователей хранятся в течение всего срока действия Учетной записи Пользователя;</li>
            <li>- персональные данные незарегистрированного Клиента хранятся в течение трех лет с момента исполнения Заказа такого Пользователя;</li>
            <li>- Персональные данные иных лиц, обращающихся в ООО «ТИЗА» с заявлениями о предполагаемом нарушении их прав, в течение всего срока обработки и рассмотрения соответствующих претензий и в течение трех лет с момента завершения обработки и (или) рассмотрения претензии, если законодательством не предусмотрен иной срок исковой давности для соответствующих споров.</li>
        </ul>
    </li>
    <li>8.2 Персональные данные, срок обработки (хранения) которых истек, должны быть уничтожены, если иное не предусмотрено законодательством Российской Федерации. Хранение персональных данных после прекращения их обработки допускается только после их обезличивания.</li>
</ol>
<h2>9. Порядок получения разъяснений по вопросам обработки Персональных Данных</h2>
<ol><li>9.1 Субъекты персональных данных, чьи персональные данные обрабатываются ООО «ТИЗА», могут получить разъяснения по вопросам обработки своих персональных данных, обратившись лично в ООО «ТИЗА» или направив соответствующий письменный запрос по адресу местонахождения ООО «ТИЗА».</li></ol>

<h2>10. Автоматически собираемая информация</h2>
<ol>
    <li>10.1 ООО «ТИЗА» может собирать и обрабатывать следующие сведения, в том числе сведения, не являющиеся персональными данными:</li>
    - информацию об интересах Пользователей на Сайте на основе введенных поисковых запросов пользователей Сайта об оказываемых услугах с целью предоставления актуальной информации Пользователям при использовании Сайта, а также обобщения и анализа информации, о том какие разделы Сайта и услуги пользуются наибольшим спросом у Пользователей Сайта. <br>
    <li>10.2 ООО «ТИЗА» осуществляет обработку и хранение поисковых запросов Пользователей Сайта с целью обобщения, и создания клиентской статистики об использовании разделов Сайта.</li> 
    <li>10.3 ООО «ТИЗА» автоматически получает некоторые виды информации, получаемой в процессе взаимодействия Пользователей с Cайтом, переписки по электронной почте и т.п.</li>
    Речь идет о технологиях и сервисах, таких как веб-протоколы, Cookies, Веб-отметки, а также приложения и инструменты третьих лиц. При этом Веб-отметки, Cookies и другие мониторинговые технологии не дают возможность автоматически получать персональные данные. Если Пользователь Сайта по своему усмотрению предоставляет свои персональные данные, например, при заполнении формы обратной связи или при отправке электронного письма, то только тогда запускаются процессы автоматического сбора подробной информации для удобства пользования веб-сайтами и (или) для совершенствования взаимодействия с пользователями.<br>
    <li>10.4 Если ООО «ТИЗА» может разумно соотнести указанные в настоящем Разделе сведения с Учетной записью конкретного Пользователя, то такие сведения могут обрабатываться совместно с Персональными Данными и иной личной информацией такого Пользователя.</li>
</ol>
<h2>11. Изменения Политики. Прочие положения</h2>
<p>11.1 ООО «ТИЗА» может периодически вносить изменения в настоящую Политику, в том числе, чтобы отражать изменения в объеме оказываемых ООО «ТИЗА» услуг или изменения в функциональности Сайта, а также изменения законодательства. Новая редакция указанных выше положений и конфиденциальности вступает в силу с момента ее опубликования, если в ней не указан иной срок ее вступления в силу.</p>`
        },
        oferta:{
            date: '',
            content: `
<h1>ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ НА ОКАЗАНИЕ ЛОГИСТИЧЕСКИХ УСЛУГ</h1>
<p>г. Москва</p>

<p>Данный документ является официальным предложением Общества с ограниченной ответственностью «Тиза Логистик» для физических и юридических лиц Российской Федерации заключить договор на оказание логистических услуг на указанных ниже условиях и публикуется на сайте https://tisalog.ru (далее – Сайт). В соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации данный документ является публичной офертой.<br>
Настоящая публичная оферта на оказание услуг (далее - Договор) заключается в особом порядке: путем акцепта настоящего Договора, содержащего все существенные условия Договора, без подписания сторонами. Настоящий Договор имеет юридическую силу в соответствии со ст. 434 Гражданского кодекса Российской Федерации и является равносильным договору, подписанному Сторонами.<br>
Настоящий Договор является Договором присоединения. Фактом, подтверждающим принятие изложенных ниже условий, и акцептом настоящей публичной оферты является заказ услуг и/или сдача груза, и/или оплата услуг, и/или получение груза, и/или подписание транспортных документов (в соответствии с пунктом 3 статьи 438 ГК РФ акцепт оферты равносилен заключению Договора на условиях, изложенных в оферте). Лицо, заказавшее услугу и/или сдавшее груз, и/или оплатившее услугу, и/или получившее груз, и/или подписавшее транспортные документы признается «Принципалом» или представителем «Принципала» с надлежащими полномочиями. <br>
Настоящий Договор считается заключенным с момента его акцептации и действует до исполнения сторонами всех своих обязательств по настоящему Договору.</p>
<p>Общество с ограниченной ответственностью «Тиза Логистик», именуемое в дальнейшем «Агент», публикует настоящий Договор, являющийся публичным Договором-офертой в адрес как физических, так и юридических лиц (в дальнейшем Принципал) о нижеследующем:</p>

<h2>1. ТЕРМИНОЛОГИЯ, ИСПОЛЬЗУЕМАЯ В ДОГОВОРЕ</h2>
<p>1.1. Терминология, используемая Сторонами в настоящем Договоре:<br>
Логистическая цепь – упорядоченное множество юридических и(или) физических лиц, включая самого Агента, задействованных в осуществлении логистических операций в отношении грузов Принципала (грузов контрагентов Принципала) с момента принятия Заявки и до момента завершения Агентом принятых на себя обязательств.<br>
Логистический цикл – интервал времени между оформлением Заявки и доставкой грузов Принципала (грузов контрагентов Принципала) на склад или иное указанное Принципалом место, результатом которого является полная реализация логистической цепочки.<br>
Логистические услуги (операции) – комплекс возмездных работ/услуг, выполняемых/оказываемых/организуемых Агентом при складировании, транспортировке, погрузке/разгрузке транспортных средств или контейнеров, планировании и оптимизации логистической цепи и логистического цикла в отношении грузов Принципала (грузов контрагентов Принципала) в рамках настоящего Договора, а именно:<br>
организация Агентом транспортно-экспедиторского обслуживания и терминальной обработки грузов Принципала (далее – «ТО»), в том числе: перевозка, погрузка/разгрузка, перевалка, охрана, хранение и др.;<br>
оказание Агентом за счет Принципала содействия в постановке и хранении грузов Принципала (грузов контрагентов Принципала) на складах. <br>
оказание иных возмездных услуг, связанных с осуществлением логистических услуг(операций) в отношении грузов Принципала (грузов контрагентов Принципала).</p>

<h2>2. ПРЕДМЕТ ДОГОВОРА</h2>
<p>2.1. По настоящему Договору Агент на основании Заявки Принципала (примерная форма Заявки указана в Приложении №1) обязуется за вознаграждение и за счет Принципала, от собственного имени совершать действия по организации представления Принципалу логистических услуг (операций), связанных с организацией транспортно-экспедиторского обслуживания и (или) терминальной обработкой грузов Принципала (грузов контрагентов Принципала), а равно оказать иные услуги, связанные с осуществлением логистических услуг (операций) в отношении грузов Принципала (грузов контрагентов Принципала), указанные в п.1.1. настоящего договора.</p>

<h2>3. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h2>
<p>3.1. Агент обязуется:</p>
<p>3.1.1. Предоставить для исполнения своих обязанностей специалистов надлежащей квалификации и обеспечить соответствующее материально-техническое обеспечение их деятельности.</p>
<p>3.1.2. Своевременно выполнить свои обязательства, согласно Заявке Принципала.</p>
<p>3.1.3. Принять на себя за вознаграждение обязательство по поручению (Заявке) Принципала, совершать мероприятия по организации терминального обслуживания и обработки грузов, а также иные юридически значимые действия на наиболее выгодных с точки зрения планирования и оптимизации логистической цепи и логистического цикла в отношении грузов Принципала (грузов контрагентов Принципал а).</p>
<p>3.1.4. По письменному указанию Принципала выступить в качестве грузоотправителя / грузополучателя;</p>
<p>3.1.5. Организовать по мере необходимости за счет Принципала транспортировку, взвешивание или иное определение количества грузов, погрузку, выгрузку, перегрузку, исправление поврежденной упаковки, вскрытие упаковки, упаковку либо переупаковку товаров Принципала (грузов контрагентов Принципал а).</p>
<p>3.1.6. В соответствии с Заявкой за счет Принципала организовать сопровождение груза в пути следования.</p>
<p>3.1.7. В соответствии с Заявкой от собственного имени за счет Принципала уплатить провозной тариф за перевозку грузов Принципала (грузов контрагентов Принципала).</p>
<p>3.1.8. Осуществлять оперативный контроль за ходом перевозки и информировать Принципала по факту его обращения.</p>
<p>3.1.9. Информировать Принципала о ходе выполнения условий договора, сроках и результатах оказанных услуг:</p>
<p>3.1.10. Оплачивать все дополнительные обоснованные расходы, возникающие при исполнении Заявки Принципала не по вине Агента, с последующим возмещением данных расходов Принципалом.</p>
<p>3.1.11. По результатам выполненной работы по конкретной Заявке Агент не позднее 15 числа месяца, следующего за месяцем доставки груза, предоставляет Принципал у Отчет Агента о выполненных работах и Акт приемки-передачи выполненных работ (услуг). Принципал обязан рассмотреть и подписать направленные Агентом документы в течение 5 (пяти) дней с момента направления. В случае отсутствия ответа Принципала в указанных срок, услуги считаются надлежащим образом оказанными и принятыми Принципалом. </p>
<p>Отчет и Акты могут высылаться для согласования посредством электронной почты, либо путем размещения в Личном кабинете Принципала на платформе Агента. </p>
<p>3.2. Агент вправе:</p>
<p>3.2.1. В течение логистического цикла осуществлять за счет Принципала по мере необходимости транспортировку, взвешивание или иное определение количества грузов, погрузку, выгрузку, перегрузку, исправление поврежденной упаковки, вскрытие упаковки, упаковку либо переупаковку грузов Принципала (грузов контрагентов Принципал а).</p>
<p>3.2.2. В любое время отказаться от выполнения обязательств по настоящему Договору при получении заведомо ложной информации о грузе, недостоверных документов, с последующей компенсацией понесенных расходов.</p>
<p>3.2.3. Расширять при необходимости логистическую цепь за счет привлечения для исполнения своих обязательств по настоящему Договору третьих лиц.</p>
<p>3.2.4. Запрашивать у Принципала необходимую информацию и документацию, связанную с выполнением Заявки Принципал а и условий Договора.</p>
<p>3.2.5. В одностороннем порядке изменять стоимость вознаграждения и расценки на услуги Агента, указанные на сайте https://tisalog.ru/. </p>
<p>Агент также вправе самостоятельно производить корректировку стоимости возмещаемых затрат в связи с повышением соответствующих установленных государством ставок и тарифов, а также стоимости услуг сторонних организаций.</p>
<p>3.2.6. В соответствии с нормами действующего законодательства осуществлять иные разрешенные действия и мероприятия в рамках реализации положений настоящего договора.</p>
<p>3.2.7. Агент вправе самостоятельно отступать от указаний Принципала, если по обстоятельствам дела это необходимо в целях оптимизации логистической цепи и логистического цикла при выполнении Заявки Принципала – в случае, если Агент не мог предварительно запросить Принципала либо не получил своевременного ответа на свой запрос.</p>
<p>3.2.8. Привлекать для исполнения своих обязательств по настоящему Договору третьих лиц. Агент несет ответственность за действия привлеченных им третьих лиц как за свои собственные в порядке и в объеме, предусмотренных действующим законодательством.</p>
<p>3.2.9. В соответствии с п.3.2.8. Договора заключать за счет Принципала соответствующие договоры с третьими лицами на оказание услуг и организацию перевозки, хранения, охрану, погрузку-выгрузку грузов. Заключать договоры на оказание других логистических услуг (операций) в отношении грузов Принципала (грузов контрагентов Принципала).</p>
<p>3.2.10. Удерживать находящийся в распоряжении Агента груз Принципала до уплаты вознаграждения при необоснованном отказе от оплаты. В указанном случае Принципал также полностью несет расходы, связанные с удержанием, хранением груза и возможной порчей груза.</p>
<p>3.3. Принципал обязуется:</p>
<p>3.3.1. Для расчета стоимости услуг заблаговременно предоставить Агенту Заявку и необходимые документы для осуществления действий Агентом. </p>
Для удобства Принципала Агент представляет Принципалу бланк определенной формы для оформления Заявок. Передача Заявки осуществляется либо непосредственно представителю Агента, либо при помощи средств электронной почты или телефонной связи.<br>
<p>3.3.2. Обеспечить присутствие своего представителя при загрузке груза для контроля за правильностью загрузки, размещения, крепления груза (в соответствии с Правилами крепления грузов) и проверки правильности сведений в товарно-сопроводительных документах (наименование, количество, вес, номера транспортных средств и пломбы, адреса и наименование получателя/отправителя и другие сведения). Факт получения груза Принципала при выгрузке заверяется подписью ответственного лица (при наличии печатью) Принципала в акте приема-сдачи груза, представляемом Агентом.</p>
<p>3.3.3. Предъявлять к перевозке грузы в таре и упаковке, предохраняющей груз от порчи и повреждения в пути следования, во время погрузки/выгрузки и других случаях.</p>
<p>3.3.4. Нести ответственность за убытки, причиненные Агенту, в связи с нарушением своих обязанностей, включая ответственность за правильность и полноту сведений, вносимых в Заявку.</p>
<p>3.3.5. Оформить надлежащим образом доверенности на право представления Агентом интересов Принципала, связанных с выполнением обязательств по настоящему Договору. </p>
<p>3.3.6. Своевременно возмещать Агенту все расходы, понесенные во исполнение условий настоящего договора, в порядке и размере, указанном Агентом.</p>
<p>3.3.7. Не вносить никаких исправлений, изменений или дополнений в составленные Агентом документы. </p>
<p>3.4. Принципал имеет право:</p>
<p>3.4.1. Запрашивать информацию и проверять ход выполнения договорных обязательств, принятых на себя Агентом.</p>
<p>3.4.2. В установленном настоящим договором порядке изменять условия договора, расторгать его.</p>
<p>3.4.3. В соответствии с нормами действующего российского законодательства осуществлять иные разрешенные действия и мероприятия в рамках реализации положений настоящего договора.</p>

<h2>4. РАСЧЕТЫ ПО НАСТОЯЩЕМУ ДОГОВОРУ</h2>
<p>4.1. Принципал при наличии потребности в организации услуг по настоящему договору подает в адрес Агента должным образом оформленную Заявку.</p>
<p>4.2. Агент на основании Заявки Принципала и Приложений к настоящему договору производит предварительный расчет стоимости возмещаемых затрат, направленных на оплату услуг третьих лиц, привлеченных для выполнения условий настоящего договора, а также вознаграждения Агента. НДС не предусмотрен. </p>
<p>4.3. Расчеты между Принципалом и Агентом осуществляются путем предварительной оплаты денежной суммы, указанной в соответствующем счете, выставляемом Агентом. </p>
<p>4.4. Оплата услуг производится в рублях РФ. </p>
<p>4.5. Принципал заблаговременно, не позднее дня выдачи груза грузополучателю переводит в соответствии с полученным счетом на расчетный счет Агента денежную сумму.</p>
<p>4.6. В случае, если стоимость услуг, фактически оказанных Принципалу, превысит сумму, ранее согласованную по Заявке к Договору, Агент в письменном виде информирует Принципала о необходимости проведения дополнительных расходов по Договору с указанием их причины и выставляет Принципалу дополнительный счет/счета. Указанный счет/счета оплачиваются Принципалом в течение 1 (одного) рабочего дня с момента получения счета от Агента.</p>
<p>4.7. Агент вправе в одностороннем порядке изменять стоимость оказания Агентом услуг и размер вознаграждения по Договору при предварительном уведомлении Принципала не менее чем за 10 (десять) дней до вступления в силу новых расценок. Принципал вправе в случае непринятия новых расценок расторгнуть настоящий Договор в одностороннем порядке.</p>
<p>4.8. Стороны пришли к соглашению о возможности передачи документов Агента при помощи средств электронной почты, при этом, указанные документы будут иметь юридическую силу до момента получения оригиналов.</p>
<p>4.9. Принципал обязуется оплачивать (возмещать, в случае, когда расходы оплачены Агентом) все расходы, услуги и вознаграждение Агента, в том числе связанные с увеличением, во время действия настоящего договора, тарифов перевозчиков, простоем транспортных средств, повышением расценок на услуги третьих лиц, привлекаемых Агентом для исполнения условий настоящего договора и иные расходы, возникшие не по вине Агента не позднее 1 (одного) рабочего дней с момента получения счета от Агента. Указанные в настоящем пункте договора счета оплачиваются Принципалом в течение 1 (одного) рабочего дня с момента получения счета от Агента.</p>

<h2>5. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
<p>5.1. За неисполнение или ненадлежащее исполнение обязанностей по настоящему договору Стороны несут ответственность, предусмотренную действующим законодательством.</p>
<p>5.2. Стороны договорились считать датой исполнения обязательств по настоящему договору дату выдачи груза грузополучателю.</p>
<p>5.3. В случае ненадлежащего исполнения Принципалом обязательств по оплате, предусмотренных п.п. 3.3.6, 4.2., 4.5., 4.7. настоящего договора, Агент оставляет за собой право применить к Принципалу неустойку в виде пени в размере 0,1 % от просроченной суммы за каждый день просрочки.</p>
<p>5.4. Если в указанном месте назначения грузополучатель отсутствует или не принимает груз в установленные сроки, то грузополучатель оплачивает услуги и расходы, связанные с возвратом, хранением, переадресовкой и т.п. операций с грузом.</p>
<p>5.5. Принципал несет всю ответственность за выбор температурного режима транспортировки, грузоподъемность и соответствие железнодорожных и автомобильных транспортных средств виду и количеству груза.</p>
<p>5.6. Принципал несет полную материальную ответственность за штрафы и прочие расходы, возникшие в случае несоответствия указанного в Заявке груза фактическому содержанию транспортного средства (автомобиля, вагона) или контейнера после загрузки.</p>
<p>5.7. Принципал несет ответственность за простои, произошедшие по вине Принципала (представителей Принципала). Время погрузки-выгрузки автомобиля с момента подачи не должно превышать 4 (четырех) часов. При задержке автомобиля свыше указанного периода по вине Принципала с Принципала взимается дополнительный тариф за каждый час.</p>
<p>5.8. При погрузке своими силами Принципал несет полную ответственность за загрузку, крепление и размещение груза в транспортном средстве.</p>
<p>5.9. Агент несет ответственность за сохранность материальных ценностей, переданных ему Принципалом.</p>
<p>5.10. Если Агент докажет, что нарушение обязательства по перевозке груза вызвано ненадлежащим исполнением договора перевозки, ответственность Агента перед Принципалом определяется по тем же правилам, по которым перед Агентом отвечает соответствующий перевозчик.</p>

<h2>6. НЕПРЕОДОЛИМАЯ СИЛА</h2>
<p>6.1. Стороны не несут ответственность, если невозможность выполнения ими своих обязательств наступила по причине форс-мажорных или иных непредотвратимых при данных условиях обстоятельствах. Стороны пришли к соглашению, что к форс-мажорным и иным непредотвратимым при данных условиях обстоятельствам приравниваются: военные действия, стихийные действия, забастовки, решения официальных органов, которые стороны не могли предотвратить или предвидеть и т.д., а также иные возможные обстоятельства, не зависящие от воли сторон настоящего договора: поломка транспортного состава, вызвавшая необходимость ремонта или замены; аварии погрузо-разгрузочного оборудования (кранов, погрузчиков, и т.д.) товарных станций и складов; автотранспорта; выход из строя систем диспетчерского контроля, сбои в электропитании.</p>
<p>6.2. При наступлении форс-мажорных обстоятельств, указанных в п.6.1., или иных непредотвратимых обстоятельств, каждая сторона должна без промедления известить о них в письменном виде другую сторону. Извещение должно содержать данные о характере обстоятельств, а также официальные документы, удостоверяющие наличие таких обстоятельств и, по возможности, дающие оценку их влияния на характер исполнения стороной своих обязательств по данному Договору.</p>

<h2>7. РАЗРЕШЕНИЕ СПОРОВ</h2>
<p>7.1. Все споры и разногласия, которые могут возникнуть между сторонами настоящего договора разрешаются в порядке, установленном действующим законодательством, в судебном порядке – по месту нахождения Агента.</p>
<p>7.2. Сторонами устанавливается предварительный претензионный порядок урегулирования споров.</p>

<h2>8. ИЗМЕНЕНИЕ И ПРЕКРАЩЕНИЕ ДОГОВОРА</h2>
<p>8.1. Настоящий договор может быть изменен или расторгнут только по письменному соглашению сторон.</p>
<p>8.2. В случае расторжения настоящего Договора, до момента выполнения всех его условий по представлению услуг, Принципал обязан в течение 10 (десяти) дней после расторжения договора самостоятельно распорядиться своим имуществом. Если Принципал не выполнит эту обязанность, Агент вправе за счет Принципал сдать имущество на хранение.</p>
<p>8.3. В случае прекращения настоящего Договора Агент сохраняет право на оплату фактически оказанных услуг, а также на возмещение понесенных до этого момента расходов.</p>

<h2>9. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
<p>9.1. Во всем остальном, что не предусмотрено настоящим договором, стороны руководствуются действующим законодательством Российской Федерации.</p>
<p>9.2. Все уведомления и сообщения должны направляться в письменной форме по реквизитам, указанным в настоящем договоре. В случае изменения реквизитов или обслуживающего банка стороны обязаны известить друг друга в трехдневный срок в любой доступной письменной форме.</p>
<p>9.3. Настоящий договор вступает в силу с момента его подписания и действует до полного исполнения обязательств Сторонами. </p>
<p>9.4. Настоящий договор составлен на русском языке в двух экземплярах, имеющих одинаковую юридическую силу, по одному экземпляру для каждой из сторон. Вся последующая переписка по настоящему договору ведется на русском языке.</p>
<p>9.5. Все Приложения являются неотъемлемой частью настоящего Договора и имеют силу при условии их подписания уполномоченными представителями Сторон.</p>

<p>Реквизиты Агента:<br>
Общество с ограниченной ответственностью «ТИЗА Логистик» (ООО «ТИЗА»)<br>
125424, ГОРОД МОСКВА, ш. Волоколамское, д. 108, помещение 8Ц <br>
ИНН / КПП 7733415489/773301001<br>
ОГРН 1237700450184<br>
р/с 40702810201910002390<br>
АО "АЛЬФА-БАНК"<br>
БИК 044525593 <br>
к/р 30101810200000000593 в ГУ БАНКА РОССИИ ПО ЦФО <br>
</p>`,
        },
    },
    getters: {
        storePolicy(state){
            return state.policy
        },
        storeOferta(state){
            return state.oferta
        }
    }
}