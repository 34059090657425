<template>
  <div class="menu-list">
    <div v-for="(item, index) in menuList" :key="index">
      <a v-if="isMainPage" class="menu-list__item" :data-id="item.id" @click.prevent.stop="onClick($event.target)">{{ item.title }}</a>
      <router-link v-else class="menu-list__item" :to=" item.id === 'app' ? '/' : `/#${item.id}`" @click="onClickLink">{{ item.title }}</router-link>
    </div>
  </div>
</template>

<script>
import {scrollToId} from "@/shared/methods/scroll";

export default {
  name: "MenuList",
  emits:['clicked'],
  data(){
    return{
      menuList:[
        {
          id: 'app',
          title: 'Главная'
        },
        {
          id: 'advantages',
          title: 'Направления и тарифы'
        },
        {
          id: 'faq',
          title: 'Часто задаваемые вопросы'
        },
        {
          id: 'partner',
          title: 'Адреса терминалов партнеров'
        },
      ]
    }
  },
  methods:{
    onClick: function (el){
      scrollToId(el)
      this.$emit('clicked')
    },
    onClickLink: function (){
      this.$emit('clicked')
    }
  },
  computed:{
    isMainPage(){
      return this.$route.path === '/'
    }
  }
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
.menu-list
  display flex
  flex-direction column
  justify-content center
  align-items center
  gap 20px
  @media screen and (min-width 768px)
    gap 30px
  @media screen and (min-width 1220px)
    flex-direction row
    justify-content space-between
  &__item
    color $greyDark
    transition 200ms
    display block
    text-align center
    &:hover
    &--active
      color $green
    &--active
      font-weight 600
</style>