<template>
  <form @submit.prevent="onSubmitForm" :class="['order-create__form', {'order-create__form--line': isCut}]">
    <slot name="cargo"/>
    <slot name="callback"/>
    <div v-if="$slots.buttonMore" class="order-create__more"><slot name="buttonMore"/></div>
    <div v-if="noteError" class="order-create__error">
      <caption-error>{{ noteError }}</caption-error>
    </div>
    <div class="order-create__button">
      <button-text-base @click="onSubmitForm" :is-loading="isLoading">Оформить заявку</button-text-base>
      <p>Нажимая кнопку, Вы соглашаетесь c
        <to-policy>
          <link-base>«Политикой по обработке персональных данных»</link-base>
        </to-policy>
      </p>
    </div>
  </form>
</template>

<script>
import CaptionError from "@/shared/ui/CaptionError";
import ButtonTextBase from "@/shared/ui/ButtonTextBase";
import LinkBase from "@/shared/ui/LinkBase";
import ToPolicy from "@/features/ToPolicy";
import {hasEmptyRequireOption, hasInvalidOption} from "@/shared/methods/validationForm";
import {mapGetters} from "vuex";
import {postOrder} from "@/shared/api/apiOrder";
export default {
  name: "OrderCreateForm",
  components: {ToPolicy, LinkBase, ButtonTextBase, CaptionError},
  props:{
    isCut:{
      type: Boolean,
      default(){
        return false
      }
    }
  },
  emits:['isSuccess'],
  data(){
    return{
      isLoading: false,
      noteError: '',
      isSuccess: this.success
    }
  },
  computed:{
    ...mapGetters(['storeGetName', 'storeGetTel', 'storeGetMail', 'storeGetDeparture', 'storeGetDestination', 'storeGetWeight', 'storeGetVolume', 'storeGetPlace']),
    options(){
      return [this.storeGetName(), this.storeGetTel(), this.storeGetMail(), this.storeGetDeparture(), this.storeGetDestination(), this.storeGetWeight(), this.storeGetVolume(), this.storeGetPlace()]
    }
  },
  methods:{
    onSubmitForm: function (){
      if(!hasEmptyRequireOption(this.options)){
        // все обязательные поля заполнены
        if(!hasInvalidOption(this.options)){
          // все поля заполнены верно
          this.toPostOrder()
        }
      }
    },

    toPostOrder: async function(){
      this.isLoading = true
      const response = await postOrder()
      const statusError = response.status
      if (statusError) {
        // есть ошибка
        this.noteError = this.handleError(statusError)
      } else {
        // нет ошибок
        this.$emit('isSuccess', true)
      }
      this.isLoading = false
    },

    handleError: function (number){
      switch (number) {
        case 500:
          return 'Ошибка сервера. Попробуйте позже'
        case 408:
          return 'Превышено время ожидания. Попробуйте позже'
        default:
          return 'Неизвестная ошибка'
      }
    }
  }
}
</script>

<style lang="stylus">
.order-create
  &__form
    text-align left
    margin-top 30px
    display flex
    flex-direction column
    gap 10px
    &--line
      @media screen and (min-width 768px)
        display grid
        grid-template-columns 1fr 1fr 1fr
        grid-template-rows auto auto auto
        grid-gap 20px
        margin-top 40px
    &__element
      margin-bottom 20px
      ^[-1]--line &
        @media screen and (min-width 768px)
          margin-bottom 0
    &--line ^[-1]__button
    &--line ^[-1]__more
    &--line ^[-1]__error
      text-align center
      @media screen and (min-width 768px)
        grid-column-start 1
        grid-column-end 4
  &__button
    max-width 380px
    margin 0 auto
    display flex
    flex-direction column
    gap 10px
    margin-top 10px
    text-align center
  &__more
    display block
</style>