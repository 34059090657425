<template>
  <div :class="['form-element__container', {
    'form-element__container--error': hasError,
    'form-element__container--grey': hasBackgroundGrey,

  }]"
       ref="container"
       @click.capture="clickInput($event)"
  >
    <div
        v-if="$slots.before"
        class="form-element__before"
    >
      <slot name="before"/>
    </div>
    <div class="form-element__inner">
      <slot name="default"/>
      <div
          v-if="$slots.reset"
          class="form-element__reset"
      >
        <slot name="reset"/>
      </div>
    </div>
    <slot name="after"/>
  </div>
</template>

<!-- Сюда нельзя caption потому что хочу позиционировать и от bottom-->

<script>
export default {
  name: "FormElementContainer",
  props:{
    hasError:{
      type: Boolean,
      default(){
        return false
      }
    },
    hasBackgroundGrey:{
      type: Boolean,
      default(){
        return false
      }
    },
  },
  methods:{
    clickInput: function (){
      if(this.$refs.container.querySelector('.form-element__input')){
        this.$refs.container.querySelector('.form-element__input').focus()
      }
    }
  },
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
@import "@/shared/styl/mixins.styl"
.form-element
  &__container
    width 100%
    position relative
    height 50px
    padding-left 10px
    padding-right 10px
    background $white
    border 1px solid transparent
    transition 100ms
    border-radius 50px
    display flex
    align-items center
    //overflow hidden убрала потому что дроптаун не видно тогда в предвыборе с селектом
    cursor pointer
    &--error
      border-color $red
    &--grey
      background #F5F5F5
  &__inner
    flex 1
    display grid
    grid-template-columns 1fr 30px
    height 100%
    width 100%
  &__reset
    display flex
    justify-content center
    align-items center
    cursor pointer
  &__before
    height 100%
    display flex
    flex-direction column
    align-items center
    justify-content center
    > *
      height 100%
</style>