<template>
  <div class="card">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "CardPrimary"
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
.card
  background $white
  box-shadow 0 30px 40px -20px rgba(0, 0, 0, 0.07)
  border-radius 30px
  padding 25px
  padding-top 40px
  padding-bottom 30px
  position relative
  width 100%
  overflow hidden
  @media screen and (min-width 768px)
    padding 40px
</style>