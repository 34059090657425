<template>
  <ButtonIconBase
      :is-disabled="isDisabled"
      :is-loading="isLoading"
      :isSmall="true"
      class="button-icon--secondary"
      :type="type"
  />
</template>

<script>
import ButtonIconBase from "@/shared/ui/ButtonIcon";
export default {
  name: "ButtonIconSecondary",
  components: {ButtonIconBase},
  props:{
    type:{
      type: String
    },
    isLoading:{
      type:Boolean,
      default(){
        return false
      }
    },
    isDisabled:{
      type:Boolean,
      default(){
        return false
      }
    }
  }
}
</script>

<style lang="stylus">
@import '@/shared/styl/variables.styl'
.button-icon--secondary
  color $white!important
  background-color $greyOpacity!important
</style>