<template>
    <div>
      <section class="main-screen" id="mainScreen">
        <div class="wrap">
          <div class="main-screen__content">
            <h1 class="main-screen__title">Комплекс <span class="highlight">транспортно-логистических</span> услуг</h1>
            <p class="main-screen__description">Полный спектр транспортно-логистических услуг, включающих транспортировку, хранение и терминальную обработку грузов от двери Отправителя до двери Получателя</p>
            <to-order class="main-screen__order"><button-text-base>Заказать грузоперевозку</button-text-base></to-order>
          </div>
        </div>
        <div class="main-screen__image"></div>
      </section>

      <section id="advantages" class="section advantages">
        <div class="wrap">
          <h2 class="section__title">Направления и тарифы</h2>
          <p class="section__description">Приоритетные направления грузоперевозок компании «ТИЗА»</p>
          <div class="advantages__list">
            <div class="advantages__item">
              <div>
                <img alt="Направления зоны охвата услуг - вся Россия" src="@/shared/assets/image/advantages-map.png" class="advantages__item__image"/>
                <h3 class="advantages__item__title">Направления зоны охвата услуг - вся Россия</h3>
                <p class="advantages__item__description">Доставляем грузы от крупнейших рынков Москвы, Санкт-Петербурга и Екатеринбурга</p>
                <ul class="advantages__item__list">
                  <li class="advantages__item__list__item advantages__item__list__item--point">До Урала</li>
                  <li class="advantages__item__list__item advantages__item__list__item--point">Сибири</li>
                  <li class="advantages__item__list__item advantages__item__list__item--point">Крайнего Севера</li>
                  <li class="advantages__item__list__item advantages__item__list__item--point">Кавказа</li>
                  <li class="advantages__item__list__item advantages__item__list__item--point">Юга</li>
                  <li class="advantages__item__list__item advantages__item__list__item--point">Дальнего востока</li>
                </ul>
              </div>
            </div>
            <div class="advantages__item">
              <div>
                <img alt="Направления зоны охвата услуг - вся Россия" src="@/shared/assets/image/advantages-special.png" class="advantages__item__image"/>
                <h3 class="advantages__item__title">Всегда в курсе событий «Рынка грузоперевозок»</h3>
                <p class="advantages__item__description">Предоставляем эксклюзивные предложения своим Заказчикам по режиму доставки</p>
                <ul class="advantages__item__list">
                  <li class="advantages__item__list__item">Экспресс</li>
                  <li class="advantages__item__list__item">Стандарт</li>
                  <li class="advantages__item__list__item">Грузовой</li>
                </ul>
              </div>
            </div>
            <div class="advantages__item advantages__item--blue">
              <div>
                <img alt="Направления зоны охвата услуг - вся Россия" src="@/shared/assets/image/advantages-profi.png" class="advantages__item__image"/>
                <h3 class="advantages__item__title">Лучшая складская и транспортная логистика</h3>
                <p class="advantages__item__description">Эксклюзивное предложение
                  <br><strong>Экспресс доставка Москва-Новосибирск за 60 часов</strong>
                </p>
              </div>
              <to-order class="advantages__item__button">Оформить заявку <IconBase type="arrowLink"/></to-order>
            </div>
          </div>
        </div>
      </section>

      <section id="faq" class="section section--grey faq">
        <div class="wrap">
          <h2 class="section__title">Часто задаваемые вопросы</h2>
          <div class="faq__list">
            <accordion-base
                v-for="item in faq" :key="item.id"
                :id="item.id"
                class="faq__item"
                @changeStatus="onChangeStatusFAQ"
            >
              <template #action>
                <div class="faq__item__title">
                  <IconBase v-if="!item.isActive" type="plus"/>
                  <IconBase v-else type="cancel"/>
                  {{ item.title }}
                </div>
              </template>
              <template #area>
                <div class="faq__item__description" v-html="item.description"></div>
              </template>
            </accordion-base>
          </div>
        </div>
      </section>

      <section id="orderCreate" class="section section--center callback">
        <div class="wrap">
          <h2 class="section__title">Расчет <span class="highlight">стоимости</span> перевозки</h2>
          <p class="section__description">Оставьте заявку на расчет стоимости и наш менеджер свяжется с Вами</p>
          <order-create-form :is-cut="true" @isSuccess="onSuccessOrderCreate">
            <template #callback><FormCallback ref="formCallback"/></template>
            <template #buttonMore><FormButtonMore/></template>
          </order-create-form>
          <OrderCreateSuccess v-if="isSuccessOrderCreate" @isSuccess="onSuccessOrderCreate"/>
        </div>
      </section>

<!--      <section id="partner" class="section section&#45;&#45;pb-none section&#45;&#45;grey">-->
<!--        <div class="wrap">-->
<!--          <h2 class="section__title">Города представительства партнера</h2>-->
<!--        </div>-->
<!--        <div class="address__container">-->
          <AddressMap/>
<!--        </div>-->
<!--      </section>-->
    </div>
</template>

<script>
import AddressMap from "@/widgets/AddressMap";
import OrderCreateSuccess from "@/features/order-create/OrderCreateSuccess";
import FormButtonMore from "@/features/order-create/FormButtonMore";
import FormCallback from "@/features/order-create/FormCallback";
import OrderCreateForm from "@/features/order-create/OrderCreateForm";
import AccordionBase from "@/shared/ui/AccordionBase";
import IconBase from "@/shared/ui/IconBase";
import ButtonTextBase from "@/shared/ui/ButtonTextBase";
import ToOrder from "@/features/ToOrder";
import {scrollByHash, scrollToId} from "@/shared/methods/scroll";

export default {
  name: "MainPage",
  components: {
    AddressMap,
    OrderCreateSuccess,
    FormButtonMore,
    FormCallback,
    OrderCreateForm,
    AccordionBase,
    IconBase,
    ButtonTextBase,
    ToOrder,
  },
  data(){
    return{
      faq:[
        {
          id: '1',
          title: 'Где передать груз к перевозке?',
          description: '<p>Города представительства партнеров указаны <a class="link-base navigation__item" data-id="partner">здесь</a></p>',
          isActive: false
        },
        {
          id: '2',
          title: 'Как и где производить оплату за перевозку?',
          description: '<p>Оплата за перевозку взимается до, или после фактически оказанной услуги, по предварительному согласованию Сторон и отражено в документе подписанном Сторонами\n</p>',
          isActive: false
        },
        {
          id: '3',
          title: 'Застрахован ли груз?',
          description: '<p>Компания «ТИЗА» несет полную материальную ответственность за вверенное имущество и принятые на себя обязательства, в рамках действующего законодательства Российской Федерации</p>',
          isActive: false
        }
      ],
      isSuccessOrderCreate: false
    }
  },
  mounted() {
    const hash = this.$route.hash
    if(hash){
      scrollByHash(hash)
      setTimeout(() => {
        this.removeHash()
      }, 2)
    }
    window.addEventListener('click', this.onClickNavigationItem);
  },
  methods:{
    removeHash: function (){
      location.hash = ''
    },

    onClickNavigationItem: function (e){
      const el = e.target
      const item = 'navigation__item'
      if(el.classList.contains(item)){
        scrollToId(el)
      }
    },

    onSuccessOrderCreate: function (boolean){
      this.isSuccessOrderCreate = boolean
      this.$refs.formCallback.toReset()
    },

    onChangeStatusFAQ(id, status){
      const array = this.faq
      for(let i = 0; i < array.length; i++){
        if(array[i].id === id){
          array[i].isActive = status
        }
      }
      this.faq = array
    },
  }
}
</script>

<style lang="stylus">
@import "@/shared/styl/main.styl"
.main-screen
  background #F8F8F8 url("@/shared/assets/image/main-screen-map.png") no-repeat center bottom / cover
  padding-top 60px
  text-align center
  @media screen and (min-width 1220px)
    padding-top 110px
  &__image
    background url("@/shared/assets/image/main-screen-mobile.webp") no-repeat center bottom / contain
    height 463px
    @media screen and (min-width 768px)
      margin-top -110px
      background url("@/shared/assets/image/main-screen.webp") no-repeat center bottom / contain
    @media screen and (min-width 1220px)
      height 496px
  &__title
    font-size 22px
    font-weight 800
    line-height 160%
    text-transform uppercase
    margin-bottom 20px
    @media screen and (min-width 768px)
      font-size 22px
    @media screen and (min-width 1220px)
      font-size 40px
  &__description
    margin 0 auto 30px
    max-width 650px

.section
  padding 60px 0
  @media screen and (min-width 768px)
    padding 80px 0
  @media screen and (min-width 1220px)
    padding 100px 0
  &--grey
    background-color #F8F8F8
  &--pb-none
    padding-bottom 0
  &--center
    text-align center
  &__title
    fontDefault()
    text-transform uppercase
    font-size 18px
    margin-bottom 10px

    @media screen and (min-width 768px)
      font-size 24px
    @media screen and (min-width 1220px)
      font-size 32px


.advantages
  &__list
    margin-top 30px
    @media screen and (min-width 768px)
      margin-top 40px
      display flex
      justify-content space-between
      align-items stretch
      gap 20px
  &__item
    padding 10px 30px 30px
    border-radius 20px
    border 1px solid $blue
    text-align center
    display flex
    flex-direction column
    justify-content space-between
    margin-bottom 20px
    @media screen and (min-width 768px)
      margin-bottom 0
      width calc( ( 100% - 40px ) / 3 )
    &:last-child
      margin-bottom 0
    &--blue
      color $white
      background-color $blue
    &__image
      display inline-block
      margin-bottom 10px
    &__title
      font-size 18px
      font-style normal
      font-weight 600
      margin-bottom 20px
    &__description
      margin-bottom 20px
    &__list
      text-align left
      &__item
        margin-bottom 7px
        padding-left 25px
        list-style none
        position relative
        &:before
          content ''
          width 20px
          height 20px
          position absolute
          top 50%
          left 0
          transform translateY(-50%)
          background url("@/shared/assets/image/ic_check.svg") no-repeat center / contain
        &--point
          &:before
            background url("@/shared/assets/image/ic_point.svg") no-repeat center / contain
        &:last-child
          margin-bottom 0
    &__button
      display flex
      align-items center
      gap 5px
      font-weight 600
      font-size 16px

.faq
  &__list
    margin-top 20px
  &__item
    border-bottom 1px solid $greyLight
    &__title
      display flex
      align-items center
      gap 20px
      padding 20px 0
      font-size 16px
      font-weight 600
    &__description
      color $blackSecondary
      padding-bottom 20px
      padding-left 40px

.address__container
  height 400px
  width 100%
</style>
