<template>
  <form-element-container :has-background-grey="hasBackgroundGrey" :has-error="hasError">
    <template #before>
      <slot name="before"/>
    </template>
    <template #default>
      <FormElementInputTel
          ref="input"
          :label="label"
          :set-value="setValue"
          :placeholder="placeholder"
          :isRu="isRu"
          @onInput="onInput"
          @onPaste="onInput"
      />
    </template>
    <template #reset>
      <IconBase v-if="value" @click="toResetInput" type="cross"/>
    </template>
    <template #after><slot name="after"/></template>
  </form-element-container>

  <!--      <div @click="onClick">-->
  <!--        <FormElementSlug value="3"/>-->
  <!--        <IconBase type="arrowDown"/>-->
  <!--      </div>-->
</template>

<!-- Сюда нельзя caption потому что целефое действие в селекте может быть на весь компонент -->

<script>
import IconBase from "@/shared/ui/IconBase";
import FormElementContainer from "@/shared/ui/FormElementContainer";
import FormElementInputTel from "@/shared/ui/FormElementInputTel";
export default {
  name: "FormInputTel",
  components: {FormElementInputTel, FormElementContainer, IconBase},
  emits:['onInput'],
  props:{
    hasError:{
      type: Boolean,
      default(){
        return false
      }
    },
    hasBackgroundGrey:{
      type: Boolean,
      default(){
        return false
      }
    },
    label: {
      type: String,
      default() {
        return null
      }
    },
    placeholder: {
      type: String,
      default() {
        return null
      }
    },
    type: {
      type: String,
      default() {
        return 'text'
      }
    },
    maxLength: {
      type: [String, Number],
      default() {
        return 'auto'
      }
    },
    setValue: {
      type: String,
      require: false
    },
    isRu: {
      type: Boolean,
    },
  },
  data(){
    return{
      value: null
    }
  },
  methods:{
    onInput: function (value){
      this.value = value
      this.$emit('onInput', value)
    },
    toResetInput: function (){
      this.$refs.input.toResetInput()
    }
  },

}
</script>

<style scoped>

</style>