<template>
  <div class="button-modal">
    <div
        @click.stop="toShow"
        class="button-modal__action"
        ref="button"
    >
      <slot name="action"/>
    </div>

    <teleport to="body">
      <transition name="fade">
        <div v-if="isActive" class="modal" ref="modal">
          <card-primary class="modal__area" @click.stop>
            <ButtonIconSecondary type="close" class="modal__area__close" @click="toHide"/>
            <slot name="area" />
          </card-primary>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import CardPrimary from "@/shared/ui/CardPrimary";
import ButtonIconSecondary from "@/shared/ui/ButtonIconSecondary";
export default {
  name: "ModalBase",
  components: {ButtonIconSecondary, CardPrimary},
  data(){
    return{
      isActive: false,
    }
  },
  methods:{
    setStatus(boolean){
      this.isActive = boolean
    },

    toShow: function (){
      const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
      this.setStatus(true)
      document.getElementById('app').style.top = `-${window.scrollY}px`;
      document.getElementById('app').style.overflowY = 'hidden'
      document.getElementById('app').style.position = 'fixed';
      document.getElementById('app').style.top = `-${scrollY}`;
      document.addEventListener('click', this.toHide, {once: true})
    },
    toHide: function (){
      const scrollY = document.getElementById('app').style.top;
      this.setStatus(false)
      document.getElementById('app').style.overflowY = 'auto'
      document.getElementById('app').style.top = '';
      document.getElementById('app').style.position = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      document.removeEventListener('click', this.toHide)
    },
  }
}
</script>

<style lang="stylus">
@import "@/shared/styl/mixins.styl"
@import "@/shared/styl/variables.styl"
.modal
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background-color rgba(0, 0, 0, 0.5)
  display flex
  flex-direction column
  justify-content center
  align-items center
  z-index 10
  &__area
    max-width 460px
    max-height 100vh
    overflow-y auto
    resetScrollBar()
    &__close
      position absolute
      right 10px
      top 10px
</style>