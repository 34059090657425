<template>
  <router-link :to="{name: 'policy', params:{id: 'policy'}}"><slot/></router-link>
</template>

<script>
export default {
  name: "ToPolicy"
}
</script>

<style scoped>

</style>