<template>
  <div class="caption"><slot></slot></div>
</template>

<script>
export default {
  name: "CaptionBase",
}
</script>

<style lang="stylus">
@import "@/shared/styl/mixins.styl"
.caption
  fontCaption()
  font-weight 400
  color inherit
</style>