<template>
  <div class="contact">
    <item-icon-base>
      <template v-slot:icon><IconBase type="point"/></template>
      <template v-slot:content>г. МОСКВА, ш-се Волоколамское, д. 108, пом. 8Ц</template>
    </item-icon-base>
    <item-icon-base v-if="hasTel">
      <template v-slot:icon><IconBase type="tel"/></template>
      <template v-slot:content><link-base><TelPrimary/></link-base></template>
    </item-icon-base>
    <item-icon-base>
      <template v-slot:icon><IconBase type="mail"/></template>
      <template v-slot:content><link-base><MailPrimary/></link-base></template>
    </item-icon-base>
  </div>
</template>

<script>
import ItemIconBase from "@/shared/ui/ItemIconBase";
import IconBase from "@/shared/ui/IconBase";
import TelPrimary from "@/features/TelPrimary";
import MailPrimary from "@/features/MailPrimary";
import LinkBase from "@/shared/ui/LinkBase";
export default {
  name: "ContactList",
  components: {LinkBase, MailPrimary, TelPrimary, IconBase, ItemIconBase},
  props:{
    hasTel:{
      type: Boolean,
      default(){
        return true
      }
    }
  }
}
</script>

<style lang="stylus">
.contact
  color inherit
</style>