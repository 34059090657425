<template>
  <caption-base class="caption--error"><slot></slot></caption-base>
</template>

<script>
import CaptionBase from "@/shared/ui/CaptionBase";
export default {
  name: "CaptionError",
  components: {CaptionBase},
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
.caption--error
  color $red
</style>