import {createRouter, createWebHistory} from "vue-router"
import SinglePage from "@/pages/SinglePage";
import MainPage from "@/pages/MainPage";

const routes = [
    {name:'home', path: '/', component: MainPage},
    {
        name:'policy',
        path: '/page/:id',
        meta: { scrollToTop: true },
        component: SinglePage,
        // scrollBehavior(to, from, savedPosition) {
        //     // always scroll to top
        //     return { top: 0 }
        // },
    },
    {name:'oferta', path: '/page/:id',  meta: { scrollToTop: true }, component: SinglePage}
]

export const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        // always scroll to top
        if(to.path !== '/' && !to.hash){
            return { top: 0 }
        }
    },
})