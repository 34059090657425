<template>
  <a href="mailto:sale@tisalog.ru">sale@tisalog.ru</a>
</template>

<script>
export default {
  name: "MailPrimary",
}
</script>

<style scoped>

</style>