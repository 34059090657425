<template>
<!--  <div>-->
    <form-element class="callback__form__element">
      <FormInput
          :set-value="storeGetName('value')"
          type="text"
          :has-background-grey="true"
          label="Ваше Имя"
          :has-error="false"
          ref="name"
          @onInput="onInputName"
      />
      <template #caption>
        <form-caption v-if="storeGetName('note')">
          <caption-error>{{storeGetName('note')}}</caption-error>
        </form-caption>
      </template>
    </form-element>
    <form-element class="callback__form__element">
      <FormInputTel
          :set-value="storeGetTel('value')"
          @onInput="onInputTel"
          ref="tel"
          :is-ru="true" type="text" :has-background-grey="true" label="Телефон" :has-error="false"/>
      <template #caption>
        <form-caption v-if="storeGetTel('note')">
          <caption-error>{{storeGetTel('note')}}</caption-error>
        </form-caption>
      </template>
    </form-element>
    <form-element class="callback__form__element">
      <FormInput
          :set-value="storeGetMail('value')"
          @onInput="onInputMail"
          ref="email"
          type="email" :has-background-grey="true" label="Почта" :has-error="false"/>
      <template #caption>
        <form-caption v-if="storeGetMail('note')">
          <caption-error>{{storeGetMail('note')}}</caption-error>
        </form-caption>
      </template>
    </form-element>
<!--  </div>-->
</template>

<script>
import FormElement from "@/shared/ui/FormElement";
import FormInput from "@/shared/ui/FormInput";
import FormCaption from "@/shared/ui/FormCaption";
import CaptionError from "@/shared/ui/CaptionError";
import FormInputTel from "@/shared/ui/FormInputTel";
import {mapGetters, mapMutations} from "vuex";
export default {
  name: "FormCallback",
  components: {FormInputTel, CaptionError, FormCaption, FormInput, FormElement},
  computed:{
    ...mapGetters(['storeGetName', 'storeGetTel', 'storeGetMail']),
  },
  methods:{
    ...mapMutations(['storeUpdateName', 'storeUpdateTel', 'storeUpdateMail']),
    onInputName: function (value){
      this.storeUpdateName({value, note: null})
    },
    onInputTel: function (value){
      this.storeUpdateTel({value, note: null})
    },
    onInputMail: function (value){
      this.storeUpdateMail({value, note: null})
    },
    toReset: function (){
        this.$refs.name.toResetInput()
        this.$refs.tel.toResetInput()
        this.$refs.email.toResetInput()
    },
  }
}
</script>

<style scoped>

</style>