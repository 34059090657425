<template>
<div class="form-element">
  <div :class="['form-element__action', {'form-element__action--disabled' : isDisabled}]">
    <slot/>
  </div>
  <form-caption v-if="$slots.caption"><slot name="caption"/></form-caption>
</div>
</template>

<script>
import FormCaption from "@/shared/ui/FormCaption";
export default {
  name: "FormElement",
  components: {FormCaption},
  props:{
    isDisabled:{
      type: Boolean,
      default(){
        return false
      }
    }
  }
}
</script>

<style lang="stylus">
.form-element
  display grid
  grid-gap 3px
  &__action
    &--disabled
      opacity .2
      pointer-events none
</style>