<template>
  <a href="tel:+74957991485">+7 (495) 799 14 85</a>
</template>

<script>
export default {
  name: "TelPrimary",
}
</script>

<style scoped>

</style>